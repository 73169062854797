import React from 'react'
import { Text, RecordRefs } from '../references/Text'
import { FieldLoop, SubmitButton } from '../formFunction'
  
const Forms = params => {

  const { data, changeField, recordType, apiAction, formId } = params
  const RecordFields = RecordRefs[recordType]

  return <form 
    className="formWrap"
    onSubmit={e => {
      e.preventDefault()
      if( e.target.checkValidity() ) apiAction()
    }}
  >
    <div className="row">
      { RecordFields.map((field, k) => {
        
        const id = `${formId}_${field.id}`
        return <div className="col-lg-12 outer" key={k}>
          { ['hidden', 'title'].includes(field.type) ? '' : <label htmlFor={id} className="form_label">{field.label}  {field.required ? Text.Required : ''}</label> }
          <FieldLoop 
            val={field} 
            id={id} 
            changeField={changeField} 
            data={data} 
          />
          { field.required && <>
            <div className="valid-feedback">"{field.label}" is valid.</div>
            <div className="invalid-feedback">"{field.label}" is a required field.</div>
          </> }
        </div>
      
      })}
    </div>
    {SubmitButton}
  </form>

} // FormBuilder

export {
  Forms
}
