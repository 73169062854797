import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import logo from '../assets/logo.svg'
import logo from '../assets/squeezed/images/bristol_squeezed_logo.png'
import { Titles } from '../references/Text'
import { SignOut } from '../routes/Auth'
import useAuth from '../hooks/useAuth'

const FrontendHeader = () => {
  
  const { auth, setAuth } = useAuth()
  const navigate = useNavigate()

  const signOut = () => {
    SignOut()
    setAuth({})
    navigate(`/login`, { replace: false })
  }
  
  return <header>
    <div className="justify-content-center">
      <Link className="custom-logo-link" to='/'>
        <img src={logo} alt="Logo" width="300" height="300" />
      </Link>
    </div>
    <div className="justify-content-center">
      <Link className="ml-2" to="/">Home</Link>
      <Link className="ml-2" to="/hours-and-location">Hours & Location</Link>
      <Link className="ml-2" to="/menu">Menu</Link>
      <Link className="ml-2" to="/contact">Contact</Link>
      

      { auth?.user && <Link className="ml-2" to="/admin">Dashboard</Link> }
      { auth?.user
        ? <span
          onClick={() => signOut()} 
          className="ml-2" 
        >{Titles.Logout} ({auth.name})</span> 
        : <Link className="ml-2" to="/login">Login</Link> 
      }
    </div>
  </header>
}

export default FrontendHeader