import React from 'react'
import { Link } from 'react-router-dom'
import { Titles, Endpoints } from '../references/Text'
import useAuth from '../hooks/useAuth'

const Sidebar = () => {

  const { auth } = useAuth()
  
  return <div className='col-lg-2 navigation'>
    <ul className="list-group mb-2">
      <li className="list-group-item p-0">
        <Link className="nav-link" to='/'>{Titles.Home}</Link>
        <Link className="nav-link" to='/admin'>{Titles.Dashboard}</Link>
        {Object.keys(Endpoints).map(key => {
          if( !auth.roles.find(role => [...Endpoints[key].view].includes(role)) ) return ''
          return <Link 
            key={key}
            className="nav-link" 
            to={`/admin${Endpoints[key].url}`}
          >{Titles[key].plural}</Link>
        })}
      </li>
    </ul>
  </div>

}

export default Sidebar