import React, { useState, useCallback } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { createRecord } from '../routes/Api'
import { Text, RecordRefs, Titles, Endpoints } from '../references/Text'
import { Forms } from '../references/Forms'
import useAuth from '../hooks/useAuth'

const formId = `form_${Math.random().toString(36).slice(2)}`

const PageCreate = () => {
  
  const { auth } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState(undefined)
  const recordType = params.recordType
  const editAccess = Endpoints[recordType]['edit']

  const apiCreateCall = async () => {
    const params = {...data, type: recordType }
    const call = await createRecord(params)
    if( call.status ) navigate(`/admin/${recordType}`, { replace: false })
  }
  
  if( data === undefined ) {
    let dataFields = {}
    RecordRefs[recordType].forEach(item => dataFields[item.id] = '')
    setData(dataFields)
  }

  const updateData = useCallback(newData => { 
    setData(newData)
  }, [setData])
  
  return <>
    <h1>{Titles.create} {Titles[recordType].single}</h1>
    <Link 
      to={`/admin/${recordType}`} 
      className="mb-4 btn btn-sm btn-primary"
    >{Text.Back}</Link>
    { !!data && auth?.roles?.find(role => editAccess.includes(role)) ? <Forms 
      data={data} 
      formId={formId}
      changeField={updateData} 
      recordType={recordType} 
      apiAction={apiCreateCall} 
    /> : <p>{Text.accessDenied}</p> }
  </>

}

export default PageCreate