import React from 'react'
import { Link } from 'react-router-dom'
import { Titles, Endpoints } from '../references/Text'
import useAuth from '../hooks/useAuth'

const Dashboard = () => {

  const { auth } = useAuth()
  document.title = `${Titles.Dashboard} | ${Titles.AppTitle}`

  return <>
    <h1>{Titles.Dashboard}</h1>
    <div className="row">
      {Object.keys(Endpoints).map(key => {
        
        if( !auth.roles.find(role => [...Endpoints[key].view].includes(role)) ) return ''
        
        return <div key={key} className="w-25">
          <div className="border p-2">
            <h3>
              <Link 
                className="title-link" 
                to={`.${Endpoints[key].url}`}
              >{Titles[key].plural}</Link>
            </h3>
            <p>{Endpoints[key].desc}</p>
            <p>
              <Link 
                className="btn btn-primary" 
                to={`.${Endpoints[key].url}`}
              >{Titles[key].plural}</Link>
            </p>
          </div>
        </div>
      })}
    </div>
  
  </>

}

export default Dashboard