import { Endpoints } from "../references/Text"

// const date_format = 'yyyy-MM-dd'

const headers = { 
  'Content-Type': 'application/json',
  Authorization: process.env.REACT_APP_API_AUTH 
}

const postCall = async ( url, params ) => {
  let data = []
  try {
    await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    }).then(response => {
      data = response
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

const getCall = async ( url, config ) => {
  let data = []
  try {
    await fetch(`${process.env.REACT_APP_API_URL}${url}${config ? '?' + new URLSearchParams(config) : ''}`, {
      method: 'GET',
      headers: headers,
    }).then(response => {
      return response.json()
    }).then(response => {
      data = response
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

const authLogin = async params => {
  return postCall('/auth/login', params)
}

const createRecord = async params => {
  const { type } = params
  return postCall(`${Endpoints[type].url}/create`, params)
}

const readRecords = async params => {
  const { type } = params
  return getCall(Endpoints[type].url, params)
}

const updateRecord = async params => {
  const { type } = params
  return postCall(`${Endpoints[type].url}/update`, params)
}

const deleteRecord = async params => {
  const { type } = params
  return postCall(`${Endpoints[type].url}/delete`, params)
}

const frontendRecords = async params => {
  return getCall('/public', params)
}

export {
  authLogin,
  createRecord,
  readRecords,
  updateRecord,
  deleteRecord,
  frontendRecords
}