import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { readRecords } from '../routes/Api'

const DetrackView = () => {
  const [data, setData] = useState(undefined)

  const { status } = useQuery('brightpearl', async () => {
    const id = 166670
    const response = await readRecords({ type: 'brightpearl', endpoint: `order-service/order/${id}` })
    if( response.status ) setData(response.response[0])    
  })
  
  if( !status === 'loading' ) console.log({ data: data })
  
  return <>
    <h1>Detrack</h1>
    <p>{JSON.stringify(data)}</p>
  </>
}

export default DetrackView