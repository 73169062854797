import React from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { readRecords, deleteRecord } from '../routes/Api'
import { Titles, Text, RecordRefs, TitleStructures, Endpoints } from '../references/Text'
import { PasswordField } from  '../references/Functions'
import useAuth from '../hooks/useAuth'
import { useQuery } from 'react-query'

const Page = () => {
  
  const { auth } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id
  const recordType = params.recordType
  const RecordFields = RecordRefs[recordType]
  const editAccess = Endpoints[recordType]['edit']

  // const [searching, setSearching] = useState(false)
  // const [data, setData] = useState(undefined)
  // const [users, setUsers] = useState(undefined)

  const deleteCall = async () => {
    const response = await deleteRecord({ type: recordType, ID: id })
    if( response.status ) navigate(`/admin/${recordType}`, { replace: false })
  }

  const { data, status } = useQuery(recordType, () => {
    return readRecords({ type: recordType, ID: id })
  })

  const { data: users } = useQuery('users', () => {
    return readRecords({ type: 'users' })
  }) 

  const PageView = () => {
    
    if( status === 'loading' ) return <p>{Text.Searching}</p>
    if( data === undefined ) return
    if( !data.response.length ) return <p>{Text.NoRecords}</p>

    const post = data.response[0]
    const PageTitle = TitleStructures[recordType].map(item => post[item]).join(' ')
    document.title = `${PageTitle} | ${Titles.AppTitle}`

    const fieldMap = () => {
      return RecordFields.map(val => {

        let recordVal = post[val.id]

        if( val.id === 'author' ) {
        
          const user = users !== undefined ? users.response.filter(user => post[val.id] === user.ID && user) : []
          if( user.length ) recordVal = user[0].name

        } else if( val.field === 'checklist' ) {

          recordVal = post[val.id] !== '' ? <ul>{post[val.id].split(';').map((item, key) => {
            return <li key={key}>{item}</li>
          })}</ul> : ''

        } else if( val.id === 'password' ) {
        
          recordVal = <p>{PasswordField(post[val.id])}</p>
        
        } else if( val.format && val.format === 'img' ) {
        
          recordVal = <img src={post[val.id]} width="100%" alt="" />
        
        } else if( val.format && val.format === 'link' ) {
          
          recordVal = post[val.id] ? <p>
            <a href={post[val.id]} target="_blank" rel="noreferrer">{val.label}</a>
          </p> : <p>No "{val.label}" set.</p>
        
        } // IF

        return <div key={val.id}>
          <h3>{val.label}</h3>
          {recordVal}
        </div>
      
      })
    }

    return <>
      <h1>{PageTitle}</h1>
      { auth?.roles?.find(role => ['admin'].includes(role)) 
        ? <button
          className="btn btn-sm btn-primary mr-1"
          onClick={() => deleteCall()}
        >{Text.Delete}</button>
      : '' }
      { auth?.roles?.find(role => editAccess.includes(role)) 
        ? <Link 
          to={`/admin/${recordType}/${post.ID}/edit`} 
          className="btn btn-sm btn-primary"
        >Edit</Link> 
      : '' }
      
      {fieldMap()}
    </>
  }

  return <>
    <PageView />
  </>

}

export default Page