import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import useAuth from '../hooks/useAuth'
import { Outlet } from 'react-router-dom'
import { tryLogin } from './Auth'

const PersistLogin = () => {
  
  const [isLoading, setIsLoading] = useState(true)
  const { auth, setAuth } = useAuth()

  useEffect(() => {
    
    const verifyRefreshToken = async () => {
    
      try {
        
        const authToken = Cookies.get('authToken')
        
        if( authToken ) {
          
          const settings = JSON.parse(CryptoJS.AES.decrypt(authToken, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8))
          
          const response = await tryLogin({ 
            username: settings.email, 
            password: settings.password 
          })

          if( response.status ) {    
            setAuth({ 
              user: true, 
              email: settings.email, 
              password: settings.password, 
              roles: ['view', response.response.level],
              name: response.response.name
            })
          }
        } // IF

      } catch (err) {

        console.error(err)

      } finally {

        setIsLoading(false)

      } // CATCH / FINAL
    
    }

    !auth?.user ? verifyRefreshToken() : setIsLoading(false)

  }, [auth, setAuth]) 

  if( isLoading ) return <p>Loading...</p>

  return <Outlet />

}

export default PersistLogin