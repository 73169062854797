import React from 'react'
import _ from 'lodash'
import { RepeaterField, SelectField, SelectFieldLookup, InputField, TextareaField, Checklist } from '../formFunction'

const FieldLoop = params => {
  
  const { val, id, changeField, data } = params

  const onChangeField = ({ name, value }) => {
    const newData = Array.isArray(data) ? [...data] : {...data}
    _.set(newData, name, value)
    changeField(newData)
  }

  let set = _.get(data, val.id, '')
  if( val.value !== undefined && set === '' ) set = val.value
  
  if( val.field === 'title' ) 
    return <h2>{val.label}</h2>
  
  if( val.field === 'input' ) 
    return <InputField onChangeField={onChangeField} val={val} id={id} set={set} />
  
  if( val.field === 'select' && val.type === 'lookup' ) 
    return <SelectFieldLookup onChangeField={onChangeField} val={val} id={id} set={set} />
  
  if( val.field === 'select' && val.type === 'text' ) 
    return <SelectField onChangeField={onChangeField} val={val} id={id} set={set} />
  
  if( val.field === 'repeater' ) 
    return <RepeaterField onChangeField={changeField} val={val} id={id} data={data} />

  if( val.field === 'textarea')
    return <TextareaField onChangeField={onChangeField} val={val} id={id} set={set} />

  if( val.field === 'checklist' )
    return <Checklist onChangeField={onChangeField} val={val} id={id} set={set} />
  
  return ''

} // FieldLoop

export default FieldLoop