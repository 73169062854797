import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Titles } from '../references/Text'
import { frontendRecords } from '../routes/Api'
import { useQuery } from 'react-query'

const breadcrumb = (records) => {
  return <ul className="breadcrumb">
    <li className="breadcrumb-item">
      <Link to="/">{Titles.Home}</Link>
    </li>
    {records.map(record => {
      return <li key={record.lookup.ID} className="breadcrumb-item">
        <Link to={`${record.lookup.full_url}`}>{record.lookup.title}</Link>
      </li>
    })}
  </ul>
}

const Frontend = () => {

  const params = useParams()
  const recordType = 'public'
  const windowPath = window.location.pathname
  
  document.title = `${params.url} | ${Titles.AppTitle}`

  const { status, data, refetch } = useQuery(recordType, () => {
    return frontendRecords({ 
      type: recordType, 
      url: windowPath
    })
  })

  useEffect(() => {
    refetch()
  }, [windowPath, refetch]);

  // let h1 = params.url
  // let desc = ''
  let content = ''
  
  if( status === 'success' && data?.response?.records[0]?.lookup ) {
    
    const final = data.response.records[data.response.records.length - 1].lookup
    // h1 = final.title
    document.title = `${final.seo_title} | ${Titles.AppTitle}`
    // desc = <p className="mb-5 pb-5 border-bottom">{final.description}</p>
    content = <div dangerouslySetInnerHTML={{ __html: final.content }} />
  }
  

  return <>
    {data?.response && breadcrumb(data?.response.records)}
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {/* <h1>{h1}</h1> */}
          {/* {status !== 'success' && <p>Status: {status}</p>} */}
          {/* {desc} */}
          {content}
        </div>
      </div>
    </div>
  </>

}

export default Frontend