import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
// import useAuth from '../hooks/useAuth'
import { authLogin } from '../routes/Api'

const SignOut = () => {  
  // const { setAuth } = useAuth()
  // setAuth({})
  Cookies.remove('authToken', { path: '/' })
} // signOut

const tryLogin = async params => {
  const { username, password } = params
  return await authLogin({ username: username, password: password })
}

const SetLogin = async params => {
  
  const { username, password, setAuth } = params
  const response = await authLogin({ username: username, password: password })
  
  if( response?.status ) {
    
    const credentials = { 
      user: true, 
      email: username, 
      password: password, 
      roles: ['view', response.response.level],
      name: response.response.name
    }
    
    setAuth(credentials)
    
    Cookies.set(
      'authToken', 
      CryptoJS.AES.encrypt(JSON.stringify(credentials), process.env.REACT_APP_SECRET).toString(),
      { expires: 7, path: '/' }
    )

  } // IF

  return response

}

export {
  SignOut,
  tryLogin,
  SetLogin,
}