import React, { useRef, useState } from 'react'
import logo from '../assets/logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Titles, Text, LoginRef } from '../references/Text'
import { SetLogin } from '../routes/Auth'
import useAuth from '../hooks/useAuth'

const Login = () => {
  
  const { setAuth } = useAuth()  
  const navigate = useNavigate()
  const location = useLocation()
  const [message, setMessage] = useState()
  const from = location.state?.from?.pathname || '/'
  const loginRef = useRef({})

  document.title = `${Titles.Login} | ${Titles.AppTitle}`

  const inputField = (key, val) => {
    
    const random = Math.random().toString(36).slice(2)
    
    return <div key={key}>
      <label 
        htmlFor={`form_${random}_${val.name}`} 
        className="form_label"
      >
        {val.label} {val.required ? Text.Required : ''}
      </label>
      <input 
        className='form-control form_field mb-3'
        type={val.type}
        ref={el => (loginRef.current[val.id] = el)}
        id={`form_${random}_${val.id}`}
        disabled={val.editable}
        required={val.required}
        autoComplete="false"
      />
      { val.required && <>
        <div className="valid-feedback">"{val.label}" is valid.</div>
        <div className="invalid-feedback">"{val.label}" is a required field.</div>
      </> }
    </div>

  } // inputField

  const handleSubmit = async e => {
    e.preventDefault()
    const response = await SetLogin({ 
      username: loginRef.current.email.value,
      password: loginRef.current.password.value,  
      setAuth
    })    
    if( response.status ) {
      navigate(from, { replace: false })
    } else {
      setMessage(response.message)
    }
  } // handleSubmit

  return <div className="mx-auto" style={{ width: '400px' }}>
    <Link className="custom-logo-link text-center w-100 d-block mt-5 mb-5" to='/'>
      <img src={logo} className="custom-logo" width='100' height='100' alt={Titles.AppTitle} title={Titles.AppTitle} />
    </Link>
    <h1>{Titles.Login}</h1>
    { message && <div className="alert alert-danger" role="alert">
      {message}
    </div> }
    <form onSubmit={e => handleSubmit(e)}>
      {LoginRef.map((val, key) => inputField(key, val))}
      <button className="btn btn-primary">{Text.Login}</button>
    </form>
  </div>

}

export default Login