import React, { Fragment } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Text, Titles } from '../references/Text'
import { deleteRecord, readRecords } from '../routes/Api'

const Database = () => {

  const recordType = 'database'
  
  document.title = `${Titles.database.single} | ${Titles.AppTitle}`

  const { status, data } = useQuery(recordType, () => {
    return readRecords({ type: recordType })
  })

  const removeRecord = type => {
    deleteRecord({
      type: recordType, 
      name: type
    })
  }
  
  const Results = () => {
        
    if( status === 'loading' ) return <p>{Text.Searching}</p>
    if( data === undefined ) return

    return Object.keys(data.response).map(key => {
      return <Fragment key={key}>
        <h3>{key}</h3>
        <Link 
          to={`/admin/${recordType}/${key}/edit`} 
          className="btn btn-sm btn-primary mr-1"
        >Edit</Link> 
        <button 
          onClick={e => {
            e.preventDefault()
            removeRecord(key)
          }} 
          className="btn btn-sm btn-error"
        >Delete</button>
        <ul>
          {data.response[key].map(
            (item, c) => <li key={`${item}_${c}`}>{item}</li>
          )}
        </ul>
      </Fragment>
    })
  }

  return <>
    <h1>{Titles[recordType].single}</h1>
    <Link 
      to={`/admin/${recordType}/add`}
      className="btn btn-sm btn-primary mb-2">
      {`${Text.Create} ${Titles.table}`}
    </Link>
    {Results()}
  </>

}

export default Database