import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { readRecords } from '../routes/Api'
import { Titles, Text, Endpoints } from '../references/Text'
import { TableHTML } from '../templates/Table'
import useAuth from '../hooks/useAuth'
import { useQuery } from 'react-query'

const PageResults = params => {
  const { status, data, recordType, users } = params
  if( status === 'loading' ) return <p>{Text.Searching}</p>
  if( data === undefined ) return <p>{Text.NoRecords}</p>
  if( !data.response.length ) return <p>{Text.NoRecords}</p>
  
  return <TableHTML 
    data={data}
    recordType={recordType}
    users={users}
  />
}

const Pages = () => {

  const { auth } = useAuth()
  const params = useParams()
  const recordType = params.recordType
  const editAccess = Endpoints[recordType]['edit']
  
  document.title = `${Titles[recordType].plural} | ${Titles.AppTitle}`

  const { status, data } = useQuery(recordType, () => {
    return readRecords({ type: recordType })
  })

  const { data: users } = useQuery('users', () => {
    return readRecords({ type: 'users' })
  })

  return <>
    <h1>{Titles[recordType].plural}</h1>
    { auth?.roles?.find(role => editAccess.includes(role)) ? <Link 
      to={`/admin/${recordType}/add`}
      className="btn btn-sm btn-primary mb-2">
      {`${Text.Create} ${Titles[recordType].single}`}
    </Link> : '' }
    <PageResults 
      status={status}
      users={users}
      data={data}
      recordType={recordType}
    />
  </>

}

export default Pages