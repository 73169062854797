import React, { useState, useCallback } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { readRecords, updateRecord } from '../routes/Api'
import { Text, Titles, TitleStructures, Endpoints } from '../references/Text'
import { Forms } from '../references/Forms'
import { useQuery } from 'react-query'
import useAuth from '../hooks/useAuth'

const formId = `form_${Math.random().toString(36).slice(2)}`

const PageEdit = () => {
  
  const { auth } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  // const [searching, setSearching] = useState(false)
  const [data, setData] = useState(undefined)
  const id = params.id
  const recordType = params.recordType
  const editAccess = Endpoints[recordType]['edit']

  const { status } = useQuery(recordType, async () => {
    const call = await readRecords({ type: recordType, ID: id })
    setData(call.response[0])
    return
  })

  const apiUpdateCall = async () => {
    const params = {...data, type: recordType }
    const call = await updateRecord(params)
    if( call.status ) navigate(`/admin/${recordType}/${id}`, { replace: false })
  }

  const updateData = useCallback(val => { 
    setData(val) 
  }, [setData])

  const suffix = ` | ${Titles.AppTitle}`
  const Title = status === 'loading' ? `${Text.Searching}${suffix}` : data === undefined ? `${Text.Searching}${suffix}` : `${Text.Edit}: ${TitleStructures[recordType].map(item => data[item]).join(' ')}`

  document.title = `${Title}${suffix}`    

  if( status === 'loading' ) return <p>{Text.Searching}</p>
  if( data === undefined ) return <p>{Text.NoRecords}</p>

  return <>
    <h1>{Title}</h1>
    <Link 
      to={`/admin/${recordType}/${data.ID}`} 
      className="mb-4 btn btn-sm btn-primary"
    >{Text.Back}</Link>
    { auth?.roles?.find(role => editAccess.includes(role)) ? 
      <Forms
        data={data}
        formId={formId}
        changeField={updateData}
        recordType={recordType}
        apiAction={apiUpdateCall}
      /> : <p>{Text.accessDenied}</p> }
  </>

}

export default PageEdit 