import React, { useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { createRecord } from '../routes/Api'
import { Text, Titles } from '../references/Text'
import { Forms } from '../references/Forms'

const formId = `form_${Math.random().toString(36).slice(2)}`

const DatabaseCreate = () => {
  
  const recordType = 'database'
  const navigate = useNavigate()
  const [data, setData] = useState({ name: '', fields: [] })

  const apiUpdateCall = async () => {
    const params = {...data, type: recordType }
    const call = await createRecord(params)
    // console.log(call)
    if( call.status ) navigate(`/admin/${recordType}`, { replace: false })
  }

  const updateData = useCallback(newData => { 
    setData(newData)
  }, [setData])
  
  document.title = `${Titles.database.single} ${Titles.table} ${Titles.create} | ${Titles.AppTitle}`

  return <>
    <h1>{`${Titles.database.single} ${Titles.table} ${Titles.create}`}</h1>
    <Link to={`/admin/${recordType}`} className="mb-4 btn btn-sm btn-primary">{Text.Back}</Link>
    <Forms
      data={data}
      formId={formId}
      changeField={updateData}
      recordType={recordType}
      apiAction={apiUpdateCall}
    />
  </>

}

export default DatabaseCreate