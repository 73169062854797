import React from 'react'

const TextareaField = params => {
  
  const { onChangeField, val, id, set } = params
  return <textarea
    id={id}
    name={val.id}
    className='form-control form_field mb-3'
    rows='10'
    value={set}
    onChange={e => onChangeField({ name: val.id, value: e.target.value })}
  />
}

export default TextareaField