import React from 'react'

const FrontendFooter = () => {
  
  const year = new Date().getFullYear()

  return <footer>
    <div className="justify-content-center">
      <p>&copy; Copyright {year}, Squeezed Bristol Ltd. - All Rights reserved.</p>
    </div>
  </footer>
}

export default FrontendFooter