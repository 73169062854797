import React from 'react'
import { Link } from 'react-router-dom'
import { Titles } from '../references/Text'
import { frontendRecords } from '../routes/Api'
import { useQuery } from 'react-query'

const Home = () => {

  document.title = `${Titles.Home} | ${Titles.AppTitle}`
  const recordType = 'public'
  const { status, data } = useQuery(recordType, () => {
    return frontendRecords({ 
      type: recordType, 
      url: '/home'
    })
  })

  let h1 = Titles.Home
  let desc = ''
  let content = ''
  
  if( status === 'success' && data?.response?.records[0]?.lookup ) {
    
    const final = data.response.records[data.response.records.length - 1].lookup
    
    h1 = final.title
    document.title = `${final.seo_title} | ${Titles.AppTitle}`
    desc = <p className="mb-5 pb-5 border-bottom">{final.description}</p>
    content = <div dangerouslySetInnerHTML={{ __html: final.content }} />
  }

  return <div className="container">
    <div className="row">
      <div className="col-lg-12">

        <h1>{h1}</h1>  
        {desc}
        {content}

        <ul>
          <li><Link to="/cars/cars/cars" title="Cars/Cars/Cars">Cars/Cars/Cars</Link></li>
          <li><Link to="/cars/cars" title="Cars/Cars">Cars/Cars</Link></li>
          <li><Link to="/cars" title="Cars">Cars</Link></li>
        </ul>
        
        <ul>
          <li><Link to="/trains/planes/automobiles" title="Trains/Planes/Automobiles">Trains/Planes/Automobiles</Link></li>
          <li><Link to="/trains/planes" title="Trains/Planes">Trains/Planes</Link></li>
          <li><Link to="/trains" title="Trains">Trains</Link></li>
        </ul>
      
      </div>
    </div>
  </div>

}

export default Home