import React from 'react'

const SelectField = params => {
  
  const { onChangeField, val, id, set } = params
  return <select
    id={id}
    name={val.id}
    className='form-control form_field mb-3'
    value={set}
    onChange={e => onChangeField({ name: val.id, value: e.target.value})}
  >
    <option value='' disabled={true}>Please select</option>
    {val.options.map((v, k) => {
      return <option key={k} value={v[0]}>{v[1]}</option>
    })}
  </select>

}

export default SelectField