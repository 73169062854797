import React, { useState, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom' // useNavigate
import { readRecords, updateRecord } from '../routes/Api'
import { Text, Titles } from '../references/Text'
import { Forms } from '../references/Forms'
import { useQuery } from 'react-query'

const formId = `form_${Math.random().toString(36).slice(2)}`

const DatabaseCreate = () => {
  
  const recordType = 'database'
  // const navigate = useNavigate()
  const [data, setData] = useState(undefined)    
  const params = useParams()
  const id = params.id

  const { status } = useQuery(recordType, async () => {
    const response = await readRecords({ type: recordType })
    const data = { name: id, fields: [] }
    response.response[id].forEach(field => {
      if( !['ID', 'created'].includes(field) ) return data.fields.push([field, ''])
    })
    setData(data)
  })

  const apiUpdateCall = async () => {
    const params = {...data, type: recordType }
    const call = await updateRecord(params)
    console.log(call)
  }

  const updateData = useCallback(newData => { setData(newData) }, [setData])
  
  document.title = `${Titles.database.single} ${Titles.table} ${Titles.create} | ${Titles.AppTitle}`

  const h1 = <h1>{`${Titles.database.single} ${Titles.table} ${Titles.create}`}</h1>
  const back = <Link to={`/admin/${recordType}`} className="mb-4 btn btn-sm btn-primary">{Text.Back}</Link>

  if( status === 'loading' ) 
    return <>{h1}{back}<p>{Text.Searching}</p></>
  
  if( data === undefined ) 
    return <>{h1}{back}<p>{Text.NoRecords}</p></>

  return <>
    {h1}
    {back}
    <Forms
      data={data}
      formId={formId}
      changeField={updateData}
      recordType={recordType}
      apiAction={apiUpdateCall}
    />  
  </>

}

export default DatabaseCreate