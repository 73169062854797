import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/logo.svg'
import { Titles } from '../references/Text'
import { SignOut } from '../routes/Auth'
import useAuth from '../hooks/useAuth'

const Header = () => {
  
  const { auth, setAuth } = useAuth()
  const navigate = useNavigate()

  const signOut = () => {
    SignOut()
    setAuth({})
    navigate(`/login`, { replace: false })
  }
  
  return <header className="site-header wp-block-template-part">
    <div className="mb-0 w-100 d-flex justify-content-between">
      <div className="d-flex">
        <div className="wp-block-site-logo">
          <Link className="custom-logo-link" to='/'>
            <img 
              src={logo} 
              className="custom-logo" 
              width='80' 
              height='80' 
              alt={Titles.AppTitle} 
              title={Titles.AppTitle} 
            />
          </Link>
        </div>
        <p className="ml-2 wp-block-site-tagline">{Titles.AppTitle}</p>
      </div>
      <div className="align-items-center d-flex ml-auto">
        <div className="lbh-header__links">
          <Link className="ml-2" to="/">Home</Link>
          { auth?.user && <Link className="ml-2" to="/admin">Dashboard</Link> }
          { auth?.user
            ? <span
              onClick={() => signOut()} 
              className="ml-2" 
            >{Titles.Logout} ({auth.name})</span> 
            : <Link className="ml-2" to="/login">Login</Link> 
          }
        </div>
      </div>
    </div>
  </header>
}

export default Header