import React from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import { Header, Sidebar, FrontendHeader, FrontendFooter } from './templates/Templates'
import * as Views from './views/Views'
import RequireAuth from './views/RequireAuth'
import PersistLogin from './routes/PersistLogin'
import DetrackView from './detrack/view'

const ViewFrontend = ({ children }) => {
  return <>
    <div className="frontend-viewer viewer">
      <FrontendHeader />
      {children}
      <FrontendFooter />
    </div>
  </>
}

const ViewAdmin = ({ children }) => {
  return <>
    <Header />
    <div className="row">
      <Sidebar />
      <div className="col-lg-10 viewer">
        {children}
      </div>
    </div>
  </>
}

const App = () => {

  return <Routes>
    {/* PUBLIC */}
    <Route path='/login' element={<Views.Login />} />

    {/* PersistLogin */}
    <Route element={<PersistLogin />}>
      
      {/* PUBLIC */}
      <Route exact path='/' element={<ViewFrontend><Views.Home /></ViewFrontend>} />  
      
      {/* Admin */}
      <Route element={<RequireAuth allowedRoles={['view']} />}>
        <Route path='/admin' element={<ViewAdmin><Views.Dashboard /></ViewAdmin>} />
      </Route>

      {/* Admin */}
      <Route element={<RequireAuth allowedRoles={['view', 'author', 'editor', 'admin']} />}>
        <Route path='/admin/detrack' element={<ViewAdmin><DetrackView /></ViewAdmin>} />
      </Route>
      
      {/* Admin */}
      <Route element={<RequireAuth allowedRoles={['admin']} />}>
        <Route path='/admin/database/:id/edit' element={<ViewAdmin><Views.DatabaseEdit /></ViewAdmin>} />
        <Route path='/admin/database/add' element={<ViewAdmin><Views.DatabaseCreate /></ViewAdmin>} />
        <Route path='/admin/database' element={<ViewAdmin><Views.Database /></ViewAdmin>}></Route>
      </Route>
      
      {/* Admin */}
      <Route element={<RequireAuth allowedRoles={['author', 'editor', 'admin']} />}>
        <Route path='/admin/:recordType/:id/edit' element={<ViewAdmin><Views.PageEdit /></ViewAdmin>} />
        <Route path='/admin/:recordType/add' element={<ViewAdmin><Views.PageCreate /></ViewAdmin>} />
      </Route>

      {/* Admin */}
      <Route element={<RequireAuth allowedRoles={['view', 'author', 'editor', 'admin']} />}>
        <Route path='/admin/:recordType/:id' element={<ViewAdmin><Views.Page /></ViewAdmin>} />
        <Route path='/admin/:recordType' element={<ViewAdmin><Views.Pages /></ViewAdmin>} />
      </Route>
    
      {/* PUBLIC */}
      <Route path='/:url/*' element={<ViewFrontend><Views.Frontend /></ViewFrontend>} />

    </Route>


    {/* catch all */}
    <Route path='*' element={<Views.Missing />} />  
  </Routes>
}

export default App
