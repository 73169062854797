import React from 'react'

const Checklist = params => {
  
  const { onChangeField, val, id, set } = params

  return <>
    <ul id={id} className="checkList">{
      val.options.map((v, k) => {
        return <li key={k}>
          <input 
            type="checkbox" 
            value={v} 
            id={`${val.id}_${v}`}
            checked={set.split(';').includes(v)} 
            onChange={() => {
              const checked = set.split(';')
              const newChecked = checked.includes(v) ? checked.filter(c => c !== v ) : [...checked, v]
              onChangeField({ name: val.id, value: newChecked.filter(c => c !== '').join(';') })
            }}
          />
          <label htmlFor={`${val.id}_${v}`}>{v}</label>
        </li>
      }
    )}</ul>
  </>

}

export default Checklist