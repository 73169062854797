const Endpoints = {
  pages: { url: '/pages', edit: ['admin', 'author', 'editor'], view: ['admin', 'author', 'editor'], desc: '...' },
  users: { url: '/users', edit: ['admin'], view: ['admin', 'author'], desc: 'Create users for the system.' },
  database: { url: '/database', edit: ['admin'], view: ['admin'], desc: 'Edit the database, create and edit tables and columns.' },
  settings: { url: '/settings', edit: ['admin'], view: ['admin'], desc: 'System settings.' },
  // brightpearl: { url: '/brightpearl', edit: ['admin'], view: ['admin'], desc: 'BrightPearl intergration' },
}

const Titles = {
  AppTitle: 'TIDB CRUD App',
  Login: 'Login',
  Logout: 'Logout',
  Home: 'Home',
  Dashboard: 'Dashboard',
  pages: {
    plural: 'Pages',
    single: 'Page',
  },
  database: {
    single: 'Database',
    plural: 'Databases',
  },
  settings: {
    single: 'Setting',
    plural: 'Settings',
  },
  users: {
    single: 'User',
    plural: 'Users',    
  },
  // brightpearl: {
  //   single: 'Brightpearl',
  //   plural: 'Brightpearl'
  // },
  table: 'Table',
  columns: 'Columns',
  create: 'Create',
}

const Text = {
  NoRecords: 'No Records found',
  Searching: 'Searching...',
  Loading: 'Loading...',
  Save: 'Save',
  Edit: 'Edit',
  Update: 'Update',
  Back: 'Back',
  Required: '(Required)',
  View: 'View',
  Delete: 'Delete',
  Create: 'Create',
  Login: 'Login',
  accessDenied: 'Access denied, you do not have permission to perform this action.'
}

const TitleStructures = {
  pages: ['title'],
  contacts: ['title', 'firstname', 'surname'],
  users: ['title', 'name'],
  settings: ['reference', 'name'],
}

const RecordRefs = {
  pages: [
    { id: 'title', label: 'Title', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'seo_title', label: 'SEO Title', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'parent', label: 'Parent', view: 'all', field: 'input', type: 'number', editable: false, required: true },
    { id: 'url', label: 'URL', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'author', label: 'Author', view: 'all', field: 'select', type: 'lookup', lookup: 'users', options: ['ID', 'name'], editable: false, required: true },
    { id: 'created', label: 'Created', view: 'long', field: 'input', type: 'hidden', editable: true, required: false },
    { id: 'description', label: 'Description', view: 'long', field: 'textarea', type: 'text', editable: false, required: false },
    { id: 'content', label: 'Content', view: 'long', field: 'textarea', type: 'text', editable: false, required: false },
  ],
  contacts: [
    { id: 'title', label: 'Title', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'created', label: 'Created on', view: 'long', field: 'input', type: 'hidden', editable: true, required: false },
    { id: 'firstname', label: 'First Name', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'surname', label: 'Surname', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'phone', label: 'Phone', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'email', label: 'Email', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'website', label: 'Website', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'company', label: 'Company', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'position', label: 'Position', view: 'all', field: 'input', type: 'text', editable: false, required: false },
  ],
  users: [
    { id: 'created', label: 'Created on', view: 'long', field: 'input', type: 'hidden', editable: true, required: false },
    { id: 'name', label: 'Name', view: 'all', field: 'input', type: 'text', editable: false, required: false },
    { id: 'email', label: 'Email', view: 'all', field: 'input', type: 'text', editable: false, required: true },
    { id: 'password', label: 'Password', view: 'long', field: 'input', type: 'password', editable: false, required: true },
    { id: 'level', label: 'Level', view: 'all', field: 'select', type: 'text', options: [['admin', 'Admin'], ['author', 'Author'], ['editor', 'Editor'], ['view', 'Viewer']], editable: false, required: true },
  ],
  database: [
    { id: 'name', label: 'Name', field: 'input', type: 'text', editable: false, required: true },
    { field: 'title', label: 'Fields', type: 'title' }, 
    { id: 'fields', field: 'repeater', structure: '["",""]', default: '[{ "id": "0", "field": "input", "type": "text" }, { "id": "1", "field": "input", "type": "text", "value": "VARCHAR(255) NOT NULL" }]' }
  ],
  databaseEdit: [
    { id: 'fields', field: 'repeater', structure: '["",""]', default: '[{ "id": "0", "field": "input", "type": "text" }, { "id": "1", "field": "input", "type": "text", "value": "VARCHAR(255) NOT NULL" }]' }
  ],
  settings: [
    { id: 'reference', label: 'Reference', field: 'input', type: 'text', editable: false, required: true },
    { id: 'name', label: 'Name / ID', field: 'input', type: 'text', editable: false, required: true },
    { id: 'data', label: 'Data', field: 'textarea', type: 'text', editable: false, required: true },
  ]
}

const LoginRef = [
  { label: 'Email Address', name: 'email', type: 'text', required: false, editable: false, id: 'email' },
  { label: 'Password', name: 'password', type: 'password', required: false, editable: false, id: 'password' }
]

export {
  Endpoints,
  Titles,
  Text,
  TitleStructures,
  RecordRefs,
  LoginRef,
}