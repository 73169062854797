import { Navigate, useParams, useLocation, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { Endpoints } from '../references/Text'

const RequireAuth = ({ allowedRoles }) => {
  
  const { auth } = useAuth()
  const location = useLocation()
  const params = useParams()

  if( params?.recordType ) allowedRoles = Endpoints[params.recordType].view

  if( !auth?.user ) return <Navigate to='/login' state={{ from: location }} replace />

  return auth?.roles?.find(role => allowedRoles?.includes(role)) ? <Outlet /> : <Navigate to='/' state={{ from: location }} replace />

}
  
export default RequireAuth