import React from 'react'
import { Text, RecordRefs } from '../references/Text'
import { Link } from 'react-router-dom'
import { PasswordField } from '../references/Functions'

const TableHead = params => {
  const { RecordFields } = params
  return <thead>
    <tr>
      <th>ID</th>
      <th>Created</th>
      {RecordFields.map(val => {
        if( val.view === 'long' ) return ''
        return <th key={val.id}>{val.label}</th>
      })}
      <th></th>
    </tr>
  </thead>
}

const TableBody = params => {
  const { data, recordType, RecordFields, users } = params
  return <tbody>
    {data.response.map(record => {
      return <tr key={record.ID}>
        <td>{record.ID}</td>
        <td>{record.created}</td>
        {RecordFields.map(val => {
        
          if( val.view === 'long' ) return ''
          let recordVal = record[val.id]
          
          if( val.type === 'lookup' && val.lookup !== 'users' ) {

          } else if( val.type === 'lookup' && val.lookup === 'users' ) {
          
            const user = users !== undefined ? users.response.filter(user => record[val.id] === user.ID && user) : []
            if( user.length ) recordVal = user[0].name
          
          } else if( val.id === 'password' ) {
          
            recordVal = PasswordField(record[val.id])
          
          } else if ( val.field === 'checklist' ) {

            recordVal = record[val.id] && record[val.id] !== '' ? <ul>{record[val.id].split(';').map((item, key) => {
              return <li key={key}>{item}</li>
            })}</ul> : ''

          } else if( val.format && val.format === 'img' ) {
          
            recordVal = record[val.id] ? <img src={record[val.id]} width="300" height="200" alt="" /> : <>No "{val.label}" set.</>
          
          } else if( val.format && val.format === 'link' ) {
          
            recordVal = record[val.id] ? <a href={record[val.id]} target="_blank" rel="noreferrer">{val.label}</a> : <>No "{val.label}" set.</>
          
          } // IF

          return <td key={val.id}>{recordVal}</td>
        
        })}
        <td>
          <Link 
            to={`/admin/${recordType}/${record.ID}`} 
            className="mr-1 btn btn-sm btn-primary"
            >{Text.View}</Link>
        </td>
      </tr>
    })}
  </tbody>
}

const TableHTML = params => {

  const { data, recordType, users } = params
  const RecordFields = RecordRefs[recordType]

  return <table>
    <TableHead RecordFields={RecordFields} />
    <TableBody data={data} recordType={recordType} RecordFields={RecordFields} users={users} />
  </table>
}

export {
  TableHead,
  TableBody,
  TableHTML,
}